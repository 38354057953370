#back-to-top {
    background: __backtotopBackgroundColor__;
}

#back-to-top:hover {
    background: __backtotopHoverColor__;
}

#back-to-top a,
#back-to-top span:before {
    color: __backtotopFontColor__;
}