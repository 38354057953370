/* Sidebar Icon Tabs Styling */
#linchpin-sidebar-wrapper {
    top: calc(__headerHeight__ + 40px + 100px);
}
#linchpin-sidebar-wrapper.sidebarstyle_icon #sidebar-tabs.aui-tabs.vertical-tabs > ul.tabs-menu li.menu-item a {
    background-color: __sidebarIconTabBackgroundColor__;
}
#linchpin-sidebar-wrapper.sidebarstyle_icon #sidebar-tabs.aui-tabs.vertical-tabs > ul.tabs-menu li.menu-item a {
    color: __sidebarIconTabColor__;
}
#linchpin-sidebar-wrapper.sidebarstyle_icon #sidebar-tabs.aui-tabs.vertical-tabs > ul.tabs-menu li.menu-item.active-tab a {
    color: __sidebarIconTabColorActive__;
}
#linchpin-sidebar-wrapper.sidebarstyle_icon #sidebar-tabs.aui-tabs.vertical-tabs > ul.tabs-menu li.menu-item a:hover {
    color: __sidebarIconTabColorActive__;
}

/* Sidebar Text Tabs Styling */
#linchpin-sidebar-wrapper.sidebarstyle_text #sidebar-tabs.aui-tabs.vertical-tabs > ul.tabs-menu li.menu-item:not(.active-tab) a {
    color: __sidebarIconTabColor__;
    background-color: __sidebarIconTabBackgroundColor__;
}

#linchpin-sidebar-wrapper.sidebarstyle_text #sidebar-tabs.aui-tabs.vertical-tabs > ul.tabs-menu li.menu-item:not(.active-tab) a:hover {
    color: __sidebarIconTabColorActive__;
}

#linchpin-sidebar-wrapper.sidebarstyle_text .aui-tabs > .tabs-menu > .menu-item.active-tab > a,
#linchpin-sidebar-wrapper.sidebarstyle_text .aui-tabs > .tabs-menu > .menu-item.active-tab > a strong {
    color: __sidebarIconTabColorActive__;
}