/* Header Background Images */
#primary-header.primary-header-background-image {
    background-image: %%headerBackground%%;
}
#header #logo {
    background-image: %%headerLogo%%;
}
.grow-html #header #logo {
    background-image: none;
}

/* Header Background Color */
#primary-header {
    background-color: __headerBackgroundColor__;
}

#linchpin-header-inner #header {
    height: calc(__headerHeight__ + 40px);
}

#logo {
    height: __headerHeight__;
}

#header #logo {
    float: __logoPosition__;
}

#primary-header {
    height: __headerHeight__;
}

#primary-header-inner {
    top: calc((__headerHeight__ - 37px) / 2);
}

#header h1.intranet-title {
    font-size: __headerIntranetnameSize__px;
    color: __headerIntranetnameColor__;
}

#header #notifications-anchor .aui-icon-small::before,
#header #user-menu-link .aui-icon-dropdown:before {
    color: __headerIconColor__;
}

.logoPosition_left #primary-header-inner .intranet-title {
    padding-left: calc(75px + __headerLogoWidth__);
}

.logoPosition_right #primary-header-inner .intranet-title {
    padding-right: calc(75px + __headerLogoWidth__);
}

#header.logoPosition_left #logo {
    width: __headerLogoWidth__;
}

#header.logoPosition_right #logo {
    width: __headerLogoWidth__;
}