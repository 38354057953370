/* Global Link Color */

a:not(.aui-button),
a:link:not(.aui-button),
a:visited:not(.aui-button),
a:focus:not(.aui-button),
a:active:not(.aui-button),
a:hover:not(.aui-button),
.wiki-content a,
.wiki-content a:link,
.wiki-content a:visited,
.wiki-content a:focus,
.wiki-content a:active,
.wiki-content a:hover,
.aui-dialog .dialog-button-panel a.button-panel-link,
.aui-dialog .dialog-page-menu li.page-menu-item button.item-button,
.aui-navgroup-horizontal .aui-nav > li > a,
.aui-navgroup-vertical .aui-nav > li > a,
#sidebar-tabs a.sidebar-toggler:before,
.aui-button.aui-button-link,
.aui-button.aui-button-link:visited,
.aui-button.aui-button-text,
.aui-button.aui-button-text:visited,
.ia-pagetree .navigation-pseudo-link,
body.spacetools table.permissions button.aui-button-text {
    color: __globalLinkColor__;
}

/* page edit "changes saved" hover color*/
#pluggable-status.synchrony a:hover {
    color: __globalLinkColor__ !important;
}

/* Space-Tools Navbar: active color (currently: same as globalLinkColor) */

.spacetools-nav.aui-navgroup-horizontal .aui-nav > .aui-nav-selected a {
    color: __globalLinkColor__;
}


/* Profil-Page Navbar: active color (currently: same as globalLinkColor) */

body.profile .user-profile .aui-navgroup-horizontal .aui-nav > .aui-nav-selected a {
    color: __globalLinkColor__;
}


/* aui-nav Headline color in search (currently: same as globalLinkColor) */

.search-results-page-panel-nav .aui-navgroup-vertical .aui-nav .aui-nav-selected > a {
    color: __globalLinkColor__;
}

.aui-navgroup-vertical .aui-nav > li > a:hover {
    background-color: #f5f5f5;
}


/* Global Blockquote Color (currently: same as globalLinkColor) */

blockquote {
    border-left: 1px solid __globalLinkColor__;
}


/* Color for "Author"-Badge in comment-section and active color of quick-comment-form (currently: same as globalLinkColor) */

.comment-header .author .author-lozenge {
    background-color: __globalLinkColor__;
}

#comments-section .quick-comment-prompt:active {
    border: 1px __globalLinkColor__ solid;
    color: __globalLinkColor__;
}


/* (Left)Sidebar Headline Colors (currently: same as globalLinkColor) */

.ia-fixed-sidebar div.plugin_pagetree ul.plugin_pagetree_children_list li .plugin_pagetree_children_content .plugin_pagetree_current a,
.acs-side-bar .current-item a {
    color: __globalLinkColor__;
}


/* (Left)Sidebar Active/Selected Link Colors (currently: same as globalLinkColor) */

.aui-sidebar[aria-expanded=false] .aui-navgroup-vertical .aui-nav .aui-nav-child-selected a,
.aui-sidebar-submenu[aria-expanded=false] .aui-navgroup-vertical .aui-nav .aui-nav-child-selected a,
.aui-sidebar .aui-navgroup-vertical .aui-nav .aui-nav-selected a,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav .aui-nav-selected a,
.aui-navgroup-vertical .aui-nav .aui-nav-selected>a,
.aui-nav-vertical .aui-nav .aui-nav-selected>a,
.aui-dialog .dialog-page-menu li.page-menu-item.selected button.item-button {
    color: __globalLinkColor__;
}


/* Shortcuts link color */

.aui-dialog .dialog-page-menu li.page-menu-item button.item-button:active,
.aui-dialog .dialog-page-menu li.page-menu-item.selected button.item-button:active {
    color: __globalLinkColor__;
    background-color: #F5F5F5;
}

/* Atlassian Activity Stream macro */

.activity-item-actions a, .activity-item-actions a:link, .activity-item-actions a:visited {
    color: __globalLinkColor__;
}

/* Confluence Expand Macro Link color */

.expand-control-text,
.expand-control-text:hover {
    color: __globalLinkColor__;
}

/*  Dialog wizard link color */

.dialog-wizard-page-wrapper .dialog-wizard-page-description h3 a {
    color: __globalLinkColor__;
}