/* START */
/* Customizable Styles for STAR Icons */

/* Stars Icons @ spans & buttons */
span.aui-iconfont-star:before,
button.aui-iconfont-star:before {
    color: __notificationColor__
}

/* Notfication Badge @ header */
#header #notifications-anchor.mw-anchor.unread .badge {
    background-color: __notificationColor__;
}

/* Fav-Page Macro */
.favpages-container .icon-remove-fav:before {
    color: __notificationColor__;
}
/* END */
