/* Datepicker Colors (currently: same as globalLinkColor) */
table.ui-datepicker-calendar a.ui-state-default:not(.ui-state-highlight) {
    color: __globalLinkColor__;
}
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar .ui-datepicker-current-day a,
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar .ui-datepicker-current-day a:hover {
    background-color: __globalLinkColor__;
}
.aui-datepicker-dialog .ui-datepicker .ui-datepicker-calendar td a {
    color: __globalLinkColor__;
}