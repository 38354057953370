/* Footer Background Color */
#footer {
    background-color: __footerBackgroundColor__;
}

/* Footer Headline Color */
#footer h2 {
    color: __footerHeadlineColor__;
}

/* Footer Text Color */
#footer tbody tr {
    color: __footerTextColor__;
}

/* Footer Link Color */
#footer .footer-body a {
    color: __footerLinkColor__;
}


