/* Menu bar und NME Hintergrundfarbe */
#sub-header,
#sub-header .menu-editor-navigation {
    background-color: __menuBarBackgroundColor__;
}

/* NME L inkfarbe */
#sub-header .menu-editor-navigation > ul > li > span,
#sub-header .menu-editor-navigation > ul > li > a,
#sub-header .menu-editor-navigation .menu-editor-navigation-right li#menu-edit-link a {
    color: __menuEditorLinkColor__;
}

/* NME Linkfarbe und Hintergrundfarbe beim hovern */
#sub-header .menu-editor-navigation > ul > li:hover > a,
#sub-header .menu-editor-navigation > ul > li > span:hover,
#sub-header .menu-editor-navigation > ul li > span:hover,
#sub-header .menu-editor-navigation > ul > li.has-children:hover,
#sub-header .menu-editor-navigation .menu-editor-navigation-right li#menu-edit-link a:hover {
    color: __menuEditorLinkHoverColor__;
    background-color: __menuEditorBackgroundHoverColor__;
}

#sub-header .menu-editor-navigation > ul > li.selected-category > .category {
    color: __menuEditorLinkHoverColor__;
}

/* NME Untermenu Linkfarbe */
#sub-header .menu-editor-navigation ul ul > li > span.nolink,
#sub-header .menu-editor-navigation ul ul li span.category,
#sub-header .menu-editor-navigation ul.aui-dropdown2.aui-style-default a,
#header .menu-editor-navigation ul.aui-dropdown2.aui-style-default a,
#header .menu-editor-navigation ul li a.menu-editor-navigation ul ul > li > a > span,
#sub-header .menu-editor-navigation ul ul > li > span.nolink {
    color: __subMenuLinkColor__;
}

/*!* NME Untermenu Linkfarbe und Hintergrundfarbe beim hovern*!*/
#sub-header .menu-editor-navigation ul ul > li > span.nolink:hover,
#sub-header .menu-editor-navigation ul.aui-dropdown2.aui-style-default li.has-children:hover,
#sub-header .menu-editor-navigation ul ul > li > span.category:hover,
#sub-header .menu-editor-navigation ul.aui-dropdown2.aui-style-default a:hover,
#header .menu-editor-navigation ul.aui-dropdown2.aui-style-default a:hover {
    color: __subMenuLinkHoverColor__ !important;
    background-color: __subMenuBackgroundHoverColor__;
}

/*!* NME Untermenu Linkfarbe und Hintergrundfarbe beim Kategorie! hovern**/

#sub-header .menu-editor-navigation.clearfix.flyout.conf-macro.output-block ul ul > li > span.category:hover {
    color: __subMenuLinkColor__ !important;
    background-color: transparent;
}

#sub-header .menu-editor-navigation li.entry-item ul.aui-dropdown2 .entry-item:hover {
    background-color: __subMenuBackgroundHoverColor__;
}