/* styles for Login-Screen */
body.login #login-logo-header,
body.terms-of-use-content #login-logo-header {
    background-color: __headerBackgroundColor__;
}

#login-logo-header {
    height: __headerHeight__;
}

#login-logo-header-inner {
    width: __headerLogoWidth__;

}

#login-logo-header #login-logo-header-inner,
#login-logo-header h1.intranet-title {
    float: __logoPosition__;
}

#login-logo-header-inner h1.intranet-title {
    line-height: __headerHeight__;
    color: __headerIntranetnameColor__;
}

#login-logo-header.logoPosition_left h1.intranet-title {
    padding-left: calc(75px + __headerLogoWidth__);
}

#login-logo-header.logoPosition_right h1.intranet-title {
    padding-right: calc(75px + __headerLogoWidth__);
}

body.login #login-logo-header-inner {
    background-image: %%headerLogo%%;
}

body.login #login-logo-header #login-logo-header-inner h1.intranet-title {
    font-size: __headerIntranetnameSize__px;
    color: __headerIntranetnameColor__;
}