/* Primary Button Color */
.aui-button.aui-button-primary,
.aui-button.aui-button-primary:visited,
.aui-button.aui-button-primary:focus,
#move-page-dialog #move-button,
#move-page-dialog #move-button:visited,
#move-page-dialog #move-button:focus
{
    background: __primaryButtonColor__;
}

.aui-button.aui-button-primary:hover,
#move-page-dialog #move-button:hover {
    background: __primaryButtonHoverColor__;
}

/* Override Buttons in form.aui */
form.aui .aui-button.aui-button-primary,
form.aui .aui-button.aui-button-primary:visited,
form.aui .aui-button.aui-button-primary:focus {
    background: __primaryButtonColor__;
}
form.aui .aui-button.aui-button-primary:hover {
    background: __primaryButtonHoverColor__;
}

#quick-create-page-button,
#create-page-button {
    background: __createButtonColor__;
    color: __createLinkColor__!important;
}
#create-page-button span {
    color: __createLinkColor__;
}

#quick-create-page-button:hover,
#create-page-button:hover {
    background: __createButtonHoverColor__;
}
