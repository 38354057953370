/* Styles for usermention (currently: same as globalLinkColor) */
.wiki-content .current-user-mention {
    background-color: __globalLinkColor__;
    border: 1px solid rgba(130, 130, 130, 0.35);
}

/* Editor Content Link Color */
#tinymce.wiki-content a:not(.current-user-mention),
#tinymce.wiki-content a:link:not(.current-user-mention),
#tinymce.wiki-content a:visited:not(.current-user-mention),
#tinymce.wiki-content a:focus:not(.current-user-mention),
#tinymce.wiki-content a:hover:not(.current-user-mention),
#tinymce.wiki-content a:active:not(.current-user-mention){
    color: __globalLinkColor__!important
}

/* Headline Colors */

#title-text a,
body.contenteditor #editor-precursor #content-title  {
    color: __titleTextColor__;
}

.wiki-content h1 {
    color: __headline1Color__;
}

.wiki-content h2 {
    color: __headline2Color__;
}

.wiki-content h3 {
    color: __headline3Color__;
}

.wiki-content h4 {
    color: __headline4Color__;
}

.wiki-content h5 {
    color: __headline5Color__;
}

.wiki-content h6 {
    color: __headline6Color__;
}


/* Panel Colors */

.panel {
    border-color: __panelBasicColor__;
}

.confluence-information-macro-note{
    border-color:  __panelNoteColor__;
}
.confluence-information-macro-note:before,
.confluence-information-macro-note:after {
    background-color: __panelNoteColor__;
}

.confluence-information-macro-tip{
    border-color: __panelTipColor__;
}
.confluence-information-macro-tip:before,
.confluence-information-macro-tip:after {
    background-color: __panelTipColor__;
}

.confluence-information-macro-information{
    border-color: __panelInfoColor__;
}
.confluence-information-macro-information:before,
.confluence-information-macro-information:after {
    background-color: __panelInfoColor__;
}

.confluence-information-macro-warning{
    border-color: __panelWarningColor__;
}
.confluence-information-macro-warning:before,
.confluence-information-macro-warning:after {
    background-color: __panelWarningColor__;
}

.confluence-information-macro:after {
    color: __panelIconColor__;
}


/* sidebar-help-tip in space config */

.spacetools.with-space-sidebar #sidebar-help-tip {
	top: 262px;
	margin-bottom: 40px;
}